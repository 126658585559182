import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul>
      <li parentName="ul">{`Smarkio: Updatet Communication Templates.`}</li>
      <li parentName="ul">{`Panels: New Metrics Panel*`}</li>
      <li parentName="ul">{`Apps: Audios app available to operators.`}</li>
      <li parentName="ul">{`Clients: Simplified client creation.`}</li>
      <li parentName="ul">{`Voice: Updated voice action list.`}</li>
      <li parentName="ul">{`Outbound: Outbound service frequently restarts.**`}</li>
      <li parentName="ul">{`Bug Fix: Ended call continues counting time.`}</li>
      <li parentName="ul">{`Bug Fix: Removed "Chatbot success" from Rules.`}</li>
      <li parentName="ul">{`Bug Fix: Prevent Unleash feature toggles from causing an error when enabling with the 'default' strategy.`}</li>
      <li parentName="ul">{`Bug Fix: Missing voice actions sequence in panels.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      